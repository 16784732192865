/* @tailwind base; */
@import '~tailwindcss/base';

/* @tailwind components; */
@import '~tailwindcss/components';

/* @tailwind utilities; */
@import '~tailwindcss/utilities';
@import './ng-select';
/*@import './vis-timeline'; */

@font-face {
  font-family: 'Lexend';
  src: url('/assets/lexend/Lexend-Light.ttf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Lexend';
  src: url('/assets/lexend/Lexend-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Lexend';
  src: url('/assets/lexend/Lexend-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Lexend';
  src: url('/assets/lexend/Lexend-Bold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@layer base {
  html {
    font-size: 11px;
  }
  @media screen and (min-width: 320px) {
    html {
      font-size: calc(11px + 3 * ((100vw - 320px) / 880));
    }
  }
  @media screen and (min-width: 1200px) {
    html {
      font-size: 14px;
    }
  }

  svg {
    height: 100%;
    width: 100%;
    fill: inherit;
    stroke: inherit;
  }

  tbody {
    vertical-align: top;
  }

  td,
  th {
    @apply py-2;
    @apply px-4;
  }

  tbody > tr {
    @apply rounded;
  }

  tbody > tr:hover {
    @apply bg-gray-100;
  }
}

.plot-container {
  height: 100%;
}

@layer utilities {
  .border-spacing-0 {
    border-spacing: 0;
  }
}
