.ng-select.ng-select-opened > .ng-select-container {
  @apply border-gray-300;
  background: #fff;
}

.ng-select.ng-select-opened > .ng-select-container:hover {
  box-shadow: none;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent #000;
  border-width: 0 5px 5px;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow:hover {
  border-color: transparent transparent #000;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  @apply rounded-t-xl;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  @apply rounded-b-xl;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  @apply border-primary;
  @apply shadow-inner;
}

.ng-select.ng-select-disabled > .ng-select-container {
  @apply bg-gray-100;
}

.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.ng-select .ng-select-container {
  @apply rounded-3xl;
  @apply border;
  @apply border-gray-300;
  @apply min-h-8;
  /* @apply py-0; */
  color: #000;
  background-color: #fff;
  align-items: center;
}

.ng-select .ng-select-container:hover {
  @apply shadow-sm;
}

.ng-select .ng-select-container .ng-value-container {
  @apply pl-4;
  align-items: center;
}

[dir='rtl'] .ng-select .ng-select-container .ng-value-container {
  @apply pr-4;
  padding-left: 0;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  @apply text-gray-300;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container {
  @apply px-4;
}

.ng-select.ng-select-opened.ng-select-bottom .ng-select-container {
  @apply rounded-b-none;
}

.ng-dropdown-panel {
  width: auto !important;
  min-width: 100%;
  z-index: theme('zIndex.over-backdrop') !important;
}

.ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  @apply pl-4;
  @apply pr-16;
  top: 5px;
  left: 0;
}

[dir='rtl']
  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  @apply pr-4;
  @apply pl-16;
  @apply py-1;
}

.ng-select.ng-select-multiple.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value {
  @apply bg-gray-100;
  @apply border-gray-200;
}

.ng-select.ng-select-multiple.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  @apply py-0;
  @apply px-2;
}

.ng-select .ng-select-container .ng-value-container {
  @apply py-1;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  @apply py-1;
  @apply px-4;
}

.ng-select.ng-select-multiple
  .ng-select-container.ng-has-value
  .ng-value-container {
  @apply pl-0;
}

[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container {
  @apply pr-2;
  @apply pl-0;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  @apply bg-primary-300;
  @apply mx-4;
  @apply rounded;
}

[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  @apply mr-0;
  @apply ml-2;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value.ng-value-disabled {
  @apply bg-gray-100;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value.ng-value-disabled
  .ng-value-label {
  @apply pl-2;
}

[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value.ng-value-disabled
  .ng-value-label {
  @apply pl-0;
  @apply pr-2;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  display: inline-block;
  @apply py-0;
  @apply px-2;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  display: inline-block;
  @apply py-0;
  @apply px-2;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon:hover {
  @apply bg-primary-400;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  @apply border-r;
  @apply border-gray-100;
}

[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  @apply border-r-0;
  @apply border-l;
  @apply border-gray-100;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.right {
  @apply border-l;
  @apply border-gray-100;
}

[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.right {
  @apply border-l-0;
  @apply border-r;
  @apply border-gray-100;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  @apply py-0;
  @apply px-2;
}

[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  @apply py-0;
  @apply px-2;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  @apply pb-2;
  @apply pl-1;
  top: 5px;
}

[dir='rtl']
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  @apply pr-1;
  padding-left: 0;
}

.ng-select .ng-clear-wrapper {
  color: #000;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
  @apply text-error;
}

.ng-select .ng-spinner-zone {
  @apply pt-1;
  @apply pr-1;
}

[dir='rtl'] .ng-select .ng-spinner-zone {
  @apply pt-1;
  @apply pr-0;
  @apply pl-1;
}

.ng-select .ng-arrow-wrapper {
  @apply w-6;
  @apply pr-2;
}

[dir='rtl'] .ng-select .ng-arrow-wrapper {
  @apply pr-0;
  @apply pl-2;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: #000;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #000 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

.ng-dropdown-panel {
  @apply shadow;
  @apply border;
  @apply border-gray-300;
  background-color: #fff;
  left: 0;
}

.ng-dropdown-panel.ng-select-bottom {
  @apply rounded-b-xl;
  top: 100%;
  margin-top: -1px;
  overflow: hidden;
}

.ng-dropdown-panel.ng-select-bottom
  .ng-dropdown-panel-items
  .ng-option:last-child {
  @apply rounded-b-xl;
}

.ng-dropdown-panel.ng-select-top {
  @apply rounded-t-xl;
  bottom: 100%;
  margin-bottom: -1px;
  overflow: hidden;
}

.ng-dropdown-panel.ng-select-top
  .ng-dropdown-panel-items
  .ng-option:first-child {
  @apply rounded-t-xl;
}

.ng-dropdown-panel .ng-dropdown-header {
  @apply border-b;
  @apply border-gray-100;
  @apply py-1;
  @apply px-4;
}

.ng-dropdown-panel .ng-dropdown-footer {
  @apply border-t;
  @apply border-gray-100;
  @apply py-1;
  @apply px-4;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  @apply font-semibold;
  @apply py-1;
  @apply px-4;
  user-select: none;
  color: #000;
  cursor: pointer;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  @apply bg-gray-100;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-optgroup.ng-option-selected.ng-option-marked {
  @apply font-semibold;
  @apply bg-gray-100;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  @apply py-2;
  @apply px-6;
  background-color: #fff;
  color: #000;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  @apply bg-gray-100;
  color: #000;
}

.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked
  .ng-option-label {
  @apply font-semibold;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  @apply bg-gray-100;
  color: #000;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  @apply text-gray;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  @apply pl-10;
}

[dir='rtl']
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-child {
  @apply pr-10;
  padding-left: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  @apply text-sm;
  @apply font-normal;
  @apply pr-1;
}

[dir='rtl']
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option
  .ng-tag-label {
  @apply pl-1;
  padding-right: 0;
}

[dir='rtl'] .ng-dropdown-panel {
  direction: rtl;
  text-align: right;
}

.ng-select.ng-select-opened.ng-select-top .ng-select-container {
  @apply rounded-t-none;
}

.ng-dropdown-panel.ng-select-top {
  @apply rounded-t-lg;
  overflow: hidden;
}
